<template lang="pug">
	aside#hoteis
		div.container
			button(@click="$parent.toggleAside('hotel')") X
			h3 Hóteis Próximos
			div.conteudo(v-html="descricao")
</template>

<script>
export default {
	components: {
	},
	props:{
		descricao: String,
	}
}
</script>

<style lang="stylus" scoped src="./AsideHotel.styl"></style>