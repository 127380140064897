<template lang="pug">
	section#programacao
		.wrapper
			h3 Programação
			span.data {{informacoes.inicio}} à {{informacoes.termino}}
			ul.datas
				li(v-for="programacao, key, index in programacoes", :class="{ativo: ativo == key}", @click="toggleProgramacao(key)")
					p {{key}}
			.programacao
				ul
					li(v-for="programacao, index in programacoes[ativo]")
						h4 das {{programacao.hora_inicio}} às {{programacao.hora_termino}} - {{programacao.local}} 
						p {{programacao.descricao}}
			
			router-link(:to="{'name': 'ingressos'}", v-if="aPartir && aPartir != 'Esgotado'").preco
				// p.apartir à partir de
				// p.valor
				// 	sup R$
				// 	|{{aPartir}}
				// 	span &nbsp;
				button Confira valores

</template>

<script>
export default {
	props: {
		programacoes: {
			type: Object,
			required: true
		},
		informacoes: {
			type: Object,
			required: true
		},
		aPartir: {
			type: String
		}
	},
	data() {
		return {
			ativo: Object.keys(this.$props.programacoes)[0]
		}
	},
	methods: {
		toggleProgramacao(index) {
			this.ativo = index;
		}
	},
}
</script>

<style lang="stylus" scoped src="./Programacao.styl">
</style>