<template lang="pug">
	section#hoteis
		.wrapper
			div
				img(:src="require('@images/icones/icone-hoteis.png')")
				h3 Hoteis
				p {{subHotel}}
				button(@click="$parent.toggleAside('hotel')") Saiba Mais
			div
				img(:src="require('@images/icones/icone-cidade.png')")
				h3 Sobre a Cidade
				p {{subCidade}}
				button(@click="$parent.toggleAside('cidade')") Saiba Mais
</template>

<script>
export default {
	components: {
	},
	props:{
		subHotel: String,
		subCidade: String,
		descricoes: Object,
	}
}
</script>

<style lang="stylus" scoped src="./Hoteis.styl">
</style>