<template lang="pug">
	aside#cidade
		div.container
			button(@click="$parent.toggleAside('cidade')") X
			h3 Sobre a Cidade: 
			div.conteudo(v-html="descricao")
</template>

<script>
export default {
	components: {
	},
	props:{
		descricao: String,
	}
}
</script>

<style lang="stylus" scoped src="./AsideCidade.styl">
</style>