<template lang="pug">
	section#sobre
		.wrapper
			h3 Sobre o evento
			ul
				li(v-if="has.palestrantes")
					div.img.border
						img(:src="medias.thumb_palestrante ? medias.thumb_palestrante : require('@images/temp/imgeventos.png')")
					h4 Palestrantes
					p {{descricoes.palestrantes}}
					router-link(:to="{name: 'palestrantes'}").link Veja mais
				li
					div.img
						img(:src="medias.thumb_local ? medias.thumb_local : require('@images/temp/imgeventos.png')")
					h4 Local do Evento
					p {{descricoes.local}}
					router-link(:to="{name: 'local'}").link Veja mais
				li(v-if="has.expositores")
					div.img.border
						img(:src="medias.thumb_expositor ? medias.thumb_expositor : require('@images/temp/imgeventos.png')")
					h4 Parceiros
					p {{descricoes.expositores}}
					router-link(:to="{name: 'expositores'}").link Veja mais
</template>

<script>
export default {
	props: {
		has:{
			type: Object,
			required: true
		},
		descricoes: {
			type: Object,
			required: true
		},
		medias: {
			type: Object,
			required: true
		}
	}
}
</script>

<style lang="stylus" scoped src="./Sobre.styl">
</style>