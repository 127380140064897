<template lang="pug">
	section#simposios
		.wrapper
			div(v-if="tema.hasTema")
				h3 {{tema.titulo}}
				p {{tema.texto}}
				a(:href="tema.link", target="_blank") Saiba Mais
			div(v-if="simposio.hasSimposio")
				h3 {{simposio.titulo}}
				p {{simposio.texto}}
				a(:href="simposio.link", target="_blank") Saiba Mais
</template>

<script>
export default {
	components: {
	},
	props:{
		simposio: Object,
		tema: Object
	}
}
</script>

<style lang="stylus" scoped src="./Simposios.styl">
</style>