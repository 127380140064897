<template lang="pug">
	section#organizacao
		.wrapper
			ul
				li(v-for="organizacao, index in organizacoes")
					div.info
						h3 {{organizacao.nome}}
						div(v-html="organizacao.descricao")
					.endereco
						span Endereço
						strong {{organizacao.endereco}}
						span Telefone
						a(:href="'tel:'+organizacao.telefone1") {{organizacao.telefone1}}
						a(:href="'tel:'+organizacao.telefone2") {{organizacao.telefone2}}
</template>

<script>
export default {
	components: {
	},
	props: {
		organizacoes: Array,
	}
}
</script>

<style lang="stylus" scoped src="./Organizacao.styl">
</style>