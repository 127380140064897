import { render, staticRenderFns } from "./AsideCidade.vue?vue&type=template&id=5ffa3dcb&scoped=true&lang=pug&"
import script from "./AsideCidade.vue?vue&type=script&lang=js&"
export * from "./AsideCidade.vue?vue&type=script&lang=js&"
import style0 from "./AsideCidade.styl?vue&type=style&index=0&id=5ffa3dcb&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ffa3dcb",
  null
  
)

export default component.exports