import { render, staticRenderFns } from "./Hoteis.vue?vue&type=template&id=3dbe6558&scoped=true&lang=pug&"
import script from "./Hoteis.vue?vue&type=script&lang=js&"
export * from "./Hoteis.vue?vue&type=script&lang=js&"
import style0 from "./Hoteis.styl?vue&type=style&index=0&id=3dbe6558&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dbe6558",
  null
  
)

export default component.exports