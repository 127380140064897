<template lang="pug">
	section#apresentacao
		.wrapper
			div.container
				h2 {{$props.titulo}}
				div(v-html="$props.texto")
</template>

<script>
export default {
	props: {
		titulo: {
			type: String,
		},
		texto: {
			type: String,
		},
	},
}
</script>

<style lang="stylus" scoped src="./Apresentacao.styl"></style>