<template lang="pug">
	section#titulo
		.wrapper
			div.container
				h2 {{titulo}}
				span {{informacoesComp}}
				div(v-html="texto")
			router-link(:to="{'name': 'ingressos'}", v-if="loteAtivo != null") Inscreva-se
			a(href="#", v-else, disabled) Vagas esgotadas
</template>

<script>
export default {
	props: {
		loteAtivo: {
			required: true
		},
		titulo: {
			type: String,
			required: true
		},
		informacoes: {
			type: Object,
			required: true
		},
		texto: {
			type: String,
			required: true
		}
	},
	computed: {
		informacoesComp() {
			return `${this.informacoes.cidade} - ${this.informacoes.inicio} à ${this.informacoes.termino}`
		}
	},
}
</script>

<style lang="stylus" scoped src="./Titulo.styl"></style>