<template lang="pug">
	article#evento
		figure.banner_evento
			img(:src="evento.medias.banner_evento", v-if="evento")
		Titulo(:titulo="evento.nome", :informacoes="informacoesComp", :texto="evento.texto", :loteAtivo="evento.lote" v-if="evento")
		Apresentacao(v-if="evento", :titulo="evento.titulo_apresentacao" :texto="evento.texto_apresentacao")
		Sobre(:has="has", :descricoes="evento.descricoes", :medias="evento.medias", v-if="evento")
		Programacao(:programacoes="evento.programacoes", :aPartir="evento.a_partir" :informacoes="informacoesComp", v-if="evento && evento.programacoes != null")
		Hoteis(:subHotel="evento.sub_hotel", :subCidade="evento.sub_cidade", :descricoes='evento.descricoes', v-if="evento")
		Simposios(:simposio="simposio" :tema="tema" v-if="evento")
		Organizacao(:organizacoes="evento.organizacoes", v-if="evento")
		AsideHotel(:descricao="evento.descricoes.hotel" :class="{'ativo': aside.hotel }" v-if="evento")
		AsideCidade(:descricao="evento.descricoes.cidade" :class="{'ativo': aside.cidade }" v-if="evento")
</template>

<script>
import Titulo from '@vue/sections/evento/Titulo/Titulo.vue'
import Apresentacao from '@vue/sections/evento/apresentacao/Apresentacao.vue'
import Sobre from '@vue/sections/evento/sobre/Sobre.vue'
import Programacao from '@vue/sections/evento/programacao/Programacao.vue'
import Hoteis from '@vue/sections/evento/hoteis/Hoteis.vue'
import Simposios from '@vue/sections/evento/simposios/Simposios.vue'
import Organizacao from '@vue/sections/evento/organizacao/Organizacao.vue'
import AsideHotel from '@vue/sections/evento/asideHotel/AsideHotel.vue'
import AsideCidade from '@vue/sections/evento/asideCidade/AsideCidade.vue'

export default {
	components: {
		Titulo,
		Apresentacao,
		Sobre,
		Programacao,
		Hoteis,
		Simposios,
		Organizacao,
		AsideHotel,
		AsideCidade,
	},
	data() {
		return {
			evento: null,
			aside: {
				hotel: false,
				cidade: false
			},
		}
	},
	computed: {
		informacoesComp() {
			if (!this.evento)
				return {}
			return {
				inicio: this.evento.inicio,
				termino: this.evento.termino,
				cidade: this.evento.local.cidade_nome,
				estado: this.evento.local.uf,
			}
		},
		tema() {
			if(!this.evento)
				return {}
			return{
				hasTema: this.evento.tem_tema_livre, 
				titulo: this.evento.titulo_tema_livre,
				texto: this.evento.texto_tema_livre,
				link: this.evento.link_tema_livre
			} 
		},
		simposio() {
			if(!this.evento)
				return {}
			return {
				hasSimposio: this.evento.tem_simposio, 
				titulo: this.evento.titulo_simposio,
				texto: this.evento.texto_simposio,
				link: this.evento.link_simposio
			} 
		},
		has(){
			let has = {
				palestrantes:false,
				expositores:false
			};
			if(this.evento.palestrantes && this.evento.palestrantes.length > 0)
				has.palestrantes = true;
			if(this.evento.expositores && this.evento.expositores.length > 0)
				has.expositores = true;
			return has;
		}
	},
	beforeRouteEnter: (to, from, next) => {
		next(vm => {
			vm.$axios
				.get(`api/eventos/${to.params.slug}`)
				.then(response => {
					vm.evento = response.data
				})
				.catch(() => {
					vm.$router.replace({ name: '404' })
				})
		})
	},
	methods: {
		toggleAside(aside) {
			this.aside[aside] = !this.aside[aside]
			if(this.aside[aside])
				document.querySelector('html').style.overflow = 'hidden'
			else
				document.querySelector('html').style.overflow = 'visible'
		}
	},
}
</script>

<style lang="stylus" scoped src="./Evento.styl"></style>